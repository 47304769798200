<template>
    <div :class="[inline ? '' : 'flex-col', 'flex']">
        <div class="flex">
            <label :for="id" :class="[disabled ? 'opacity-40' : '', 'block mb-1 text-sm font-medium text-gray-700']">{{
                label
            }}</label>
            <span v-if="required" :class="starStyle">*</span>
        </div>
        <div :class="allInputStyle">
            <component :is="iconStart" :class="iconStyle" @click="focusInput"></component>
            <flat-pickr
                v-model="inputValue"
                ref="flatpickrRef"
                :config="config"
                :id="id"
                :class="inputStyle"
                :disabled="disabled"
                @input="disableValidationError"
            ></flat-pickr>
        </div>
        <p v-if="localErrorMessage" class="mt-2 text-sm text-red-600">{{ localErrorMessage }}</p>
    </div>
</template>

<script lang="ts" setup>
import { Component, computed, ref, watch } from "vue"
import flatPickr from "vue-flatpickr-component"
import "flatpickr/dist/flatpickr.css"
import { formatDateToIso } from "@/shared/utils/helpers"

interface Props {
    position?: string
    modelValue: string | Date | undefined
    showTime?: boolean
    showDate?: boolean
    label?: string
    id?: string
    disabled?: boolean
    inline?: boolean
    minDate?: string
    required?: boolean
    errorMessage?: string
    invalid?: boolean
    iconStart?: Component
    isForm?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    inline: false,
    isForm: false,
})
const emit = defineEmits(["update:modelValue"])

let internalValue = ""
const flatpickrRef = ref(null)

const config = computed(() => ({
    position: props.position,
    enableTime: props.showTime,
    noCalendar: !props.showDate,
    minDate: props.minDate === "now" ? new Date() : props.minDate,
    dateFormat: props.showDate ? "m/d/Y" : "h:i K", // Flatpickr format for AM/PM
    ...(!props.isForm && { allowInvalidPreload: true }),
}))

const inputValue = computed({
    get: () => {
        if (props.modelValue !== internalValue) {
            internalValue = String(props.modelValue) ?? ""
            if (props.modelValue instanceof Date && !isNaN(props.modelValue.getTime())) {
                const date = new Date(props.modelValue)
                const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
                return newDate.toISOString().slice(0, -1)
            } else if (typeof props.modelValue === "string") {
                return formatDateToIso(props.modelValue).slice(0, -1)
            }
        }
        return internalValue
    },
    set: (value: string | Date) => {
        internalValue = String(value)
        emit("update:modelValue", value)
    },
})

const localErrorMessage = ref(props.errorMessage)
const localInvalid = ref(props.invalid)

const inputStyle = computed(() => [
    localInvalid.value && !inputValue.value ? "pr-10 placeholder-red-300" : "placeholder-gray-400",
    props.disabled ? "text-gray-400" : "",
    "appearance-none block w-full pl-2 py-2 border-0 focus:outline-none text-base rounded-md",
])

const allInputStyle = computed(() => [
    localInvalid.value && !inputValue.value
        ? "border-red-300 focus-within:ring-red-500 focus-within:border-red-500"
        : "focus-within:ring-primary-color-500 focus-within:border-primary-color-500 border-gray-300",
    "relative flex w-full  border rounded-md shadow-sm focus-within:outline-none text-base focus-within:ring-1",
])

const iconStyle = computed(() => "p-2 w-10 border-0 bg-white rounded-md text-gray-400 cursor-pointer")

const starStyle = computed(() => [localInvalid.value ? "text-red-600" : "text-gray-700", "pl-1"])

function disableValidationError() {
    localErrorMessage.value = ""
    localInvalid.value = false
}

const focusInput = () => {
    if (flatpickrRef.value) {
        flatpickrRef.value.$el.focus()
    }
}

watch(
    () => props.errorMessage,
    (newVal) => {
        localErrorMessage.value = newVal
        localInvalid.value = props.invalid
    }
)

watch(
    () => props.invalid,
    () => {
        localInvalid.value = props.invalid
    }
)

watch(
    () => props.minDate,
    (newMinDate) => {
        if (flatpickrRef.value && flatpickrRef.value?.config) {
            flatpickrRef.value.config.minDate = newMinDate === "now" ? new Date() : newMinDate
        }
    },
    {
        immediate: false,
    }
)
</script>
<style scoped>
input {
    --tw-ring-shadow: 0 0 #000 !important;
}
</style>
