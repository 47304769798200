import { RouteRecordRaw } from "vue-router"

export const contactRoutes: Array<RouteRecordRaw> = [
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/home/DashboardView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/contacts",
        name: "contacts",
        component: () => import("@/views/home/ContactsView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/contacts/:id",
        name: "profile",
        component: () => import("@/views/contacts/ContactProfileViewWithWidgets.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/tasks",
        name: "tasks",
        component: () => import("@/views/home/TasksView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/team",
        name: "team",
        component: () => import("@/views/home/TeamView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/agency-settings",
        name: "agencySettings",
        component: () => import("@/views/home/CompanySettingsView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/import-contacts",
        name: "importContacts",
        component: () => import("@/views/home/ImportContactsView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/fields",
        name: "fields",
        component: () => import("@/views/home/PropertiesView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/tags",
        name: "tags",
        component: () => import("@/views/home/TagsView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/jobs",
        name: "jobs",
        component: () => import("@/views/home/JobsView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/create-field",
        name: "createField",
        component: () => import("@/views/home/CreateNewPropertyView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/my-settings",
        name: "mySettings",
        component: () => import("@/views/home/MySettingsView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/templates",
        name: "templates",
        component: () => import("@/views/home/TemplateView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/landing-pages",
        name: "landing-pages",
        component: () => import("@/views/home/LandingPagesView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/workflows",
        name: "workflows",
        component: () => import("@/views/home/WorkflowView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/workflows/create",
        name: "createWorkflow",
        component: () => import("@/views/home/WorkflowCreationView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/workflows/:id",
        name: "workflowPreview",
        component: () => import("@/views/home/WorkflowCreationView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/phone-numbers",
        name: "phoneNumbers",
        component: () => import("@/views/home/PhoneNumbersView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/developers/api-keys",
        name: "apiKeys",
        component: () => import("@/views/home/DeveloperView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/developers/track-events",
        name: "trackEvents",
        component: () => import("@/views/home/TrackEventsView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/audit",
        name: "audit",
        component: () => import("@/views/home/AuditView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/billing",
        name: "billing",
        component: () => import("@/views/home/PlansAndBillingView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/themes",
        name: "themes",
        component: () => import("@/views/home/ThemeView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/agencies",
        name: "agencies",
        component: () => import("@/views/home/ChildAgencyView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/agencies/:id",
        name: "agencyProfile",
        component: () => import("@/views/home/AgencyProfileView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/integrations",
        name: "integrations",
        component: () => import("@/views/home/IntegrationView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/integrations/:id",
        name: "integrationDetails",
        component: () => import("@/views/home/IntegrationDetailView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/forms",
        name: "forms",
        component: () => import("@/views/home/FormView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/forms/create",
        name: "createForm",
        component: () => import("@/views/home/FormCreationView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/forms/:id",
        name: "formDetails",
        component: () => import("@/views/home/FormCreationView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/form/preview",
        name: "formDetailsWithoutId",
        component: () => import("@/views/home/FormPreviewView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/form/:id/preview",
        name: "formDetailsWithPreview",
        component: () => import("@/views/home/FormPreviewView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/form/:id/responses",
        name: "formResponses",
        component: () => import("@/views/home/FormResponsesView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/form/:id/responses/:responseId/preview",
        name: "formResponseDetailsWithPreview",
        component: () => import("@/views/home/FormPreviewView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/inbox",
        name: "inbox",
        component: () => import("@/views/home/InboxView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/lists",
        name: "lists",
        component: () => import("@/views/home/CollectionView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/domains",
        name: "domains",
        component: () => import("@/views/home/DomainView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/domains/configuration/:id",
        name: "domainConfiguration",
        component: () => import("@/components/domains/DomainConfiguration.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/origins",
        name: "origins",
        component: () => import("@/views/home/OriginsView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/logs",
        name: "logs",
        component: () => import("@/views/home/EventLogsView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/listings",
        name: "listings",
        component: () => import("@/views/home/ListingsView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/listings/:id",
        name: "listingDetails",
        component: () => import("../views/home/ListingDetailedView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/mls-feeds",
        name: "mlsFeeds",
        component: () => import("@/views/home/MlsFeedsView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/developers/analytics-api",
        name: "analyticsApi",
        component: () => import("@/views/home/AnalyticsApiView.vue"),
        meta: { requiresAuth: true },
    },
]
